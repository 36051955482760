// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$meteo-website-frontend-primary: mat-palette($mat-blue);
$meteo-website-frontend-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$meteo-website-frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$meteo-website-frontend-theme: mat-light-theme(
  (
    color: (
      primary: $meteo-website-frontend-primary,
      accent: $meteo-website-frontend-accent,
      warn: $meteo-website-frontend-warn,
    ),
  )
);

@import "./assets/css/base/base";
@import "./assets/css/base/variables";

// Components
@import "./assets/css/components/cs-button";
@import "./assets/css/components/top-header";
@import "./assets/css/components/sub-top-header";
@import "./assets/css/components/header";
@import "./assets/css/components/navbar";
@import "./assets/css/components/ng-dropdown-menu";
@import "./assets/css/components/ng-custom-select";
@import "./assets/css/components/toggle-group";
@import "./assets/css/components/footer";
@import "./assets/css/components/flash-info";
@import "./assets/css/components/prevision-card";
@import "./assets/css/components/tabs";
@import "./assets/css/components/ol-popup";
@import "./assets/css/components/carousel";
@import "./assets/css/components/cs-paginator";

@import "./assets/css/base/typography";
@import "./assets/css/base/utilities";

@font-face {
  font-family: sansationBold;
  src: url(./assets/fonts/sansation/Sansation_Bold.ttf);
}
@font-face {
  font-family: SansationBold;
  src: url(./assets/fonts/sansation/Sansation_Bold.ttf);
}
@font-face {
  font-family: AlJazeeraArabicRegular;
  src: url(./assets/fonts/Al_Jazeera_Arabic/Al-Jazeera-Arabic-Regular.ttf);
}
@font-face {
  font-family: ProximaNovaRegular;
  src: url(./assets/fonts/ProximaNovaA/ProximaNovaA-Regular.ttf);
}
@font-face {
  font-family: GeSSTwoBold;
  src: url(./assets/fonts/GE_SS_Two/GE_SS_Two_Bold.otf);
}

.divider-secondary {
  margin: 0.7rem 0;
  border-bottom: 1px solid var(--primary-white-2);
}

.temp-cold {
  color: var(--color-temp-cold);
}

.temp-warm {
  color: var(--color-temp-high);
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);

  &--white {
    background-color: white;
  }
}

.tool-tip {
  font-size: 1.6rem;
  font-weight: 500;
  background-color: white !important;
  color: var(--primary-black-2) !important;
  box-shadow: var(--shadow-1);
  white-space: pre-line;
  font-family: SansationBold;
}

.cs-dialog {
  //min-width: 95vw;
  //min-height: 90vh;

  @media (max-width: 768px) {
    min-width: 95vw;
  }

  .mat-dialog-container {
    padding-top: 0px; // Change for responsiveness
    margin: 0 auto;
    overflow-x: hidden;
    border-radius: 3px 3px 6px 6px;

    .mat-dialog-title {
      padding: 0.5rem 24px;
      margin-bottom: 0;
      margin-left: -24px;
      margin-right: -24px;
      background-color: var(--primary-blue);
      text-align: center;
      color: white;
    }

    .mat-dialog-content {
      padding: 0;
      overflow: auto;
    }
  }
}

.grid-center {
  display: grid;
  place-items: center;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($meteo-website-frontend-theme);

.cs-article-content-body img {
  box-shadow: var(--shadow-border-2);

  @media (max-width: 48em) {
    width: 100%;
    height: auto;
  }
}

.cs-article-content-body table {
  border-spacing: 0;
  border-collapse: separate;
  border: 0;
  // max-width: 100%;
  // overflow-x: auto;
  // display: block;

  /*@media screen and (max-width: 600px) {
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
      margin-bottom: 0.625em;
      height: auto !important;
    }

    td {
      display: block;
      font-size: 0.8em;
      text-align: right;
      width: 100% !important;
    }

    td::before {

      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      //width: 100% !important;
    }

    td:last-child {
      border-bottom: 0;
    }
  }*/
}
.cs-article-content-body table tr:nth-child(even) {
  background: rgba(189, 204, 212, 0.2);
}

.cs-article-content-body table tr {
  border-bottom: 1px solid rgba(83, 108, 120, 0.2);
}
.cs-article-content-body table caption,
.cs-article-content-body table td,
.cs-article-content-body table th {
  padding: 1rem 0.5rem;
  border: 0;
  font: inherit;
}

/*.cs-article-content-body table td:first-child {
  font-weight: 500;
}*/

.cs-article-content-body table thead {
  background-color: var(--primary-blue);
  font-weight: 400;
  color: #fff;
  box-shadow: var(--shadow-1);
  font-family: SansationBold;
}

.cs-article-content-body table th {
  background-color: var(--primary-blue);
  font-weight: 400;
  color: #fff;
  box-shadow: var(--shadow-1);
}

.ng2-pdf-viewer-container {
  position: relative;
}

mat-menu-panel {
  border: 1px dashed red;
}

body {
  font-family: sansationBold;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font: 400 14px/20px sansationBold;
  letter-spacing: normal;
}
.mat-option-text {
  font-family: SansationBold;
}

.mat-form-field-infix {
  font-family: SansationBold;
}
