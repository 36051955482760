// BTNS
.btn {
  padding: 0rem 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 500;
  transition: background-color 0.3s;
}

.btn-readmore {
  //color: var(--primary-blue);
  color: #28367f;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 1.6rem;
  transition: background-color 0.3s;
  background-color: none;

  &:hover {
    color: var(--primary-blue-dark);
  }
}

.btn-danger {
  background-color: var(--color-danger);
  color: white;

  &:hover {
    background-color: var(--color-danger-dark);
  }

  &:active {
    background-color: var(--color-danger);
  }
}

.btn-warning {
  background-color: var(--color-warning);
  color: white;

  &:hover {
    background-color: var(--color-warning-dark);
  }

  &:active {
    background-color: var(--color-warning);
  }
}

.btn-sm {
  height: 3.5rem;
}

.btn-primary {
  //background-color: var(--primary-blue);
  background-color: #0098da;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  color: #fff;

  &:hover {
    background-color: var(--primary-blue-dark);
  }

  &:active {
    background-color: var(--primary-blue);
  }
}

.btn-secondary {
  border-radius: 100px;
  background-color: #fff;
  border: 2px solid var(--primary-blue);

  &:hover {
    background-color: var(--primary-blue-dark);
    color: white;
  }

  &:active {
    background-color: var(--primary-blue);
    color: white;
  }
}

.btn-alert {
  padding: 0 1rem;
  //box-shadow: 0 0 0 0 var(--color-vigilance-rouge);
  //background-color: var(--color-vigilance-vert);
  color: white;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 3px;
  white-space: nowrap;

  &:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
  }

  &--pulse {
    //background-color: var(--color-vigilance-rouge);
    color: #fff;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &--jaune {
    color: var(--primary-black);
    background-color: var(--color-vigilance-jaune);
  }

  &--vert {
    background-color: var(--color-vigilance-vert);
  }

  &--orange {
    background-color: var(--color-vigilance-orange);
    box-shadow: 0 0 0 0 var(--color-vigilance-orange);
  }

  &--rouge {
    background-color: var(--color-vigilance-rouge);
    box-shadow: 0 0 0 0 var(--color-vigilance-rouge);
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 3rem rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 3rem rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 3rem rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 3rem rgba(232, 76, 61, 0);
  }
}
