// OL POPÛP
.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  bottom: 5px;
  right: -150px;
  min-width: max-content;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.95);

  &--mobile {
    position: relative;
    inset: 0;
    filter: none;
    border: none;
  }

  &--bl-previsions {
    bottom: -130px;
    right: 0;
  }

  &--tl-previsions {
    bottom: 0px;
    right: 0;
  }

  &--br-previsions {
    bottom: -150px;
    right: -200px;
  }

  &--tr-previsions {
    bottom: 0px;
    right: -200px;
  }

  &--c-previsions {
    bottom: -170px;
    right: -150px;
  }

  // VG
  &--bl-vigilanceGenerale {
    bottom: -70px;
    right: 0;
  }

  &--tl-vigilanceGenerale {
    bottom: 0px;
    right: 0;
  }

  &--br-vigilanceGenerale {
    bottom: -95px;
    right: -230px;
  }

  &--tr-vigilanceGenerale {
    bottom: 10px;
    right: -230px;
  }

  &--c-vigilanceGenerale {
    bottom: -170px;
    right: -150px;
  }

  // VM

  &--bl-vigilanceMarine {
    bottom: -70px;
    right: 0;
  }

  &--tl-vigilanceMarine {
    bottom: 100px;
    right: 0;
  }

  &--br-vigilanceMarine {
    bottom: -100px;
    right: -150px;
  }

  &--tr-vigilanceMarine {
    bottom: 0px;
    right: -200px;
  }

  &--c-vigilanceMarine {
    bottom: -170px;
    right: -150px;
  }
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

/*.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    right: 28px;
    margin-right: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    right: 28px;
    margin-right: -11px;
}*/

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
  display: none;
}

.popup-img {
  display: grid;
  place-items: center;
  border-radius: 100px;
  padding: 8px;
  margin: 1rem;
  width: 40px;
  height: 40px;
  box-shadow: var(--shadow-border-2);

  &--empty {
    width: 8px;
    height: 8px;
  }
}

.popup-vg {
  position: relative;
  display: flex;
  flex-direction: column;

  &__header {
    align-self: center;
    color: var(--primary-black-3);
    font-weight: 500;
  }

  &__content {
    padding-top: 1rem;
    display: flex;
    &__icon {
      font-size: 2rem;
      padding: 1rem;
      box-shadow: var(--shadow-border-2);
      margin-right: 1rem;
      border-radius: 100px;
    }

    &__description {
      align-self: center;
      color: var(--primary-black-3);
      font-weight: 500;
    }
  }
}

.popup-prev {
  position: relative;
  display: flex;
  flex-direction: column;

  &__picto {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__header {
    align-self: center;
    color: var(--primary-black-3);
    font-weight: 500;
    font-size: 1.5rem;
  }

  &__temp {
    align-self: center;
    color: var(--primary-black-3);
    font-weight: 500;
    font-size: 1.5rem;
  }

  &__desc {
    align-self: center;
    color: var(--primary-black-3);
    font-weight: 400;
    font-size: 1.5rem;
    padding: 0.25rem;
  }

  &__mesures {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 0.5rem;
  }

  &__mesure-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  &__mesure-item {
    color: var(--primary-black-3);
    font-weight: 500;
    font-size: 1.25rem;
    align-self: center;
    &:nth-child(even) {
      align-self: end;
      justify-self: ce;
    }
  }
}
