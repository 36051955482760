.custom-select.mat-select-panel {

    .mat-option {
        line-height: 4rem;
        height     : 4rem;
        font-size  : 1.4rem;
        font-weight: 500;

        &:hover {
            background-color: var(--primary-blue-highlight-2);
        }
    }
}

.cs-tab-group {
    .mat-tab-header {
        display: none;
    }

}