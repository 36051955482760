.sub-top-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  //color: var(--primary-blue-dark);
  padding: 0.5rem 11rem;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid var(--primary-blue-highlight);

  // Modif
  //background-color: var(--primary-blue);
  color: var(--bg-primary);

  @media (max-width: 80em) {
    padding: 0.3rem 5rem;
  }

  @media (max-width: 70.625em) {
    padding: 0.3rem 2.5rem;
    display: none;
  }

  /*@media (max-width: 67.5em) {
        padding: 0.3rem 2.5rem;
    }*/

  @media (max-width: 48em) {
    padding: 0.3rem 1rem;
    display: none;
  }

  li {
    padding: 0.2rem 0.6rem;
    line-height: 1.5rem;
    cursor: pointer;
  }

  &__left {
    display: flex;
    list-style: none;

    li {
      //text-transform: uppercase;
      text-decoration: none;
      //letter-spacing: 0.15em;
      display: flex;
      white-space: nowrap;
      padding: 0.75rem 0.5rem;
      position: relative;
      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: var(--primary-blue);
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      &:hover:after {
        width: 100%;
        left: 0;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    list-style: none;
  }

  &__icon--right {
    padding: 0 0.6rem;
    font-size: 1.6rem;
  }
}
