// Lyout

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.min-h-full {
  min-height: 100%;
}

.grid {
  display: grid;
}

.place-items-center {
  place-items: center;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: end;
}

.flex-column {
  flex-direction: column;
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-space-between {
  justify-content: space-between;
}

.bg-vigilance-vert {
  background-color: var(--color-vigilance-vert);
}

.bg-vigilance-jaune {
  background-color: var(--color-vigilance-jaune);
  //filter: invert(76%) sepia(82%) saturate(513%) hue-rotate(5deg) brightness(110%) contrast(103%);
  //border: 2px solid var(--color-vigilance-jaune);
}

.bg-vigilance-orange {
  background-color: var(--color-vigilance-orange);
}

.bg-vigilance-rouge {
  background-color: var(--color-vigilance-rouge);
}

.bg-primary {
  background-color: var(--primary-blue);
}

.bg-white {
  background-color: #fff;
}

.hidden {
  display: none;
}

// SHADOWS

.shadow-primary {
  box-shadow: var(--shadow-primary);
}

.shadow-1 {
  box-shadow: var(--shadow-1);
}

.shadow-2 {
  box-shadow: var(--shadow-2);
}

.shadow-3 {
  box-shadow: var(--shadow-3);
}

.shadow-4 {
  box-shadow: var(--shadow-4);
}

.shadow-border {
  box-shadow: var(--shadow-border-2);
}

.shadow-border-blue {
  box-shadow: var(--shadow-border-blue);
}

.shadow-material {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

// RADIUS
.rounded-xs {
  border-radius: 3px;
}

.rounded-sm {
  border-radius: 6px;
}

// PADDING

.p-xs {
  padding: 0.5rem;
}

.p-sm {
  padding: 1rem;
}

.p-md {
  padding: 2rem;
}

.p-lg {
  padding: 4rem;
}

.pb-sm {
  padding-bottom: 1rem;
}

.pt-sm {
  padding-top: 1rem;
}

.pt-xs {
  padding-top: 0.5rem;
}

.py-sm {
  padding: 1rem 0;
}

.py-md {
  padding: 2rem 0;
}

.px-sm {
  padding: 0 1rem;
}

.px-lg {
  padding: 0 2rem;
}

.p-md {
  padding: 1.5rem;
}

.p-lg {
  padding: 3rem;
}

.p-xl {
  padding: 4.5rem;
}

// MARGIN

.m-sm {
  margin: 1rem;
}

.m-md {
  margin: 2rem;
}

.mb-xs {
  margin-bottom: 0.5rem;
}

.mb-sm {
  margin-bottom: 1rem;
}

.mb-md {
  margin-bottom: 2rem;
}

.m-xs {
  margin: 0.5rem;
}

.mt-sm {
  margin-top: 1rem;
}

.mt-md {
  margin-top: 2rem;
}

.mt-lg {
  margin-top: 4rem;
}

.my-sm {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-md {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-sm {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-md {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-lg {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mr-sm {
  margin-right: 1rem;
}

.mr-xs {
  margin-right: 0.5rem;
}

.mr-xxs {
  margin-right: 0.25rem;
}

.ml-sm {
  margin-left: 1rem;
}

.ml-md {
  margin-left: 2rem;
}

.ml-xs {
  margin-left: 0.5rem;
}
