*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  width: 100%;
  font-size: 62.5%; //1rem = 10px, 10px/16px = 62.5%
  //height: 100%;

  @media (max-width: 1280px) {
    font-size: 60%;
  }
}

/** {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
}*/

@font-face {
  font-family: sansationBold;
  src: url(../../fonts/sansation/Sansation_Bold.ttf);
}

body {
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: sansationBold;
  box-sizing: border-box;
  //font-size  : 62.5%; //1rem = 10px, 10px/16px = 62.5%
}
