:root {
  // Colors
  //--color-temp-cold: #5dade2;
  --color-temp-cold: #28367f;
  --color-temp-high: #e74c3c;

  // Shadow

  //rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --shadow-primary: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --shadow-1: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --shadow-2: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --shadow-3: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --shadow-4: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --shadow-border: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --shadow-border-2: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  --shadow-border-blue: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(16, 133, 200, 0.15) 0px 0px 0px 1px;
  --shadow-b: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  // Website
  --bg-primary: #f8f9f9; // #F2F4F4
  --bg-content-primary: #fdfefe;
  --primary-black: #283747;
  --primary-white: #f7fbfb;
  --primary-white-2: #d1d7dd;
  --primary-white-3: #7f8c8d;
  --primary-black-2: #4f5b63;
  --primary-black-3: #586c82;
  //    --primary-blue: #1085c8;
  //--primary-blue: rgba(13, 167, 239, 0.76);
  --primary-blue: #28367f;
  // --primary-blue-dark: #014d78;
  --primary-blue-dark: #28367f;
  //--primary-blue-highlight: #d5e5f4;
  --primary-blue-highlight: #28367f;
  --primary-blue-highlight-2: #eaf2f8;
  --primary-blue-highlight-3: #f7f9f9;
  --primary-blue-highlight-dark-1: #72b6dd;
  --color-bg-map: #eaf2f8;
  --bms-red: #d9003d;
  --green-1: #28b463;
  // --red-1: crimson;
  --red-1: #cf3628;
  --color-error: #e24242;

  --color-danger: #ff0000;
  --color-danger-light: #e24242;
  --color-danger-dark: #df0505;

  --color-warning: #f39c12;
  --color-warning-light: #f5b041;
  --color-warning-dark: #e67e22;

  // CSS variables accessed globally
  // --color-primary: #457b9d;
  --color-primary: #28367f;
  //--color-primary-lighter: #5d95b8;
  --color-primary-lighter: #28367f;
  --color-primary-light: #81acc7;
  --color-primary-dark: #2e5168;
  --color-primary-highlight: #eaf2f8;
  --color-primary-notification: #aed6f1;

  --color-vigilance-rouge: #f40808;
  --color-vigilance-orange: #fd8008;
  --color-vigilance-jaune: #fafa00;
  --color-vigilance-vert: #137605;

  // Borders
  --main-radius: 6px;

  --color-purple: #8e44ad;

  --color-secondary: #48c9b0;
  --color-secondary-lighter: #6fd5c1;
  --color-secondary-light: #97e0d2;
  --color-secondary-dark: #2d9883;
  --color-secondary-highlight: #c7f5ec;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #444;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --color-white: #fff;

  --color-state-active: #02c39a;
  --color-state-off: #e63946;
  --color-state-on: #f1c40f;
  --color-state-none: #a6acaf;

  --color-graph-state-on: #f9e79f;
  --color-graph-state-off: #f1948a;
  --color-graph-state-active: #82e0aa;
}
