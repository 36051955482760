.switch-field {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  // background-color    : var(--primary-blue-highlight-2);
  background-color: #778186;
  display: grid;
  place-items: center;
  //color: var(--primary-black);
  color: #fff;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  padding: 0.8rem 1.6rem;
  //margin-right      : -1px;
  //border          : 1px solid rgba(0, 0, 0, 0.2);
  transition: 0.3s background-color ease, 0.3s box-shadow ease;
  width: 100%;
  height: 5rem;
}

.switch-field label:hover {
  cursor: pointer;
  background-color: var(--primary-blue-highlight);
  box-shadow: 0 1px 0 #f4f4f4 inset;
}

.switch-field input:checked + label {
  color: #778186;
  background-color: #fff;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 6px 0 0 0;
}

.switch-field label:last-of-type {
  border-radius: 0 6px 0 0;
}
