.footer-container {
  //background-color: var(--primary-black);
  background-color: #28367f;
  padding: 1.5rem 20rem;

  @media (max-width: 80em) {
    padding: 1rem 10rem;
  }

  @media (max-width: 67.5em) {
    padding: 1rem 10rem;
  }

  @media (max-width: 48em) {
    padding: 1rem 1rem;
  }
}

.footer-top-links {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  letter-spacing: 0.15rem;

  & li {
    cursor: pointer;
  }
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 0.5rem;
  color: var(--primary-white-2);

  @media (max-width: 67.5em) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  @media (max-width: 48em) {
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.footer-contact {
  &__address {
    display: grid;
    grid-template-columns: 64px 1fr;
    gap: 0.5rem;
  }

  &__coordinates {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;

    &__item {
      display: grid;
      grid-template-columns: 1rem 1fr;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 0.5rem;
    }
  }
}

.footer-address-title {
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.footer-address-content {
  font-size: 1.4rem;
  color: #0098da;
}

.mobile-ad {
  width: 250px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 6px;

  &:hover {
    transform: scale(1.05);
  }
}

.footer-link {
  text-decoration: none;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    //background: var(--primary-blue);
    background: #0098da;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover:after {
    width: 100%;
    left: 0;
  }
}
