.header {
  display: grid;
  grid-template-columns: 47% 35% 17% 1%;
  place-items: center;
  gap: 1%;
  padding: 0rem 12rem;
  //background-image: url("../../../assets/img/capture.PNG");
  background-color: #28367f;

  overflow: hidden;
  min-height: 16rem;

  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 80em) {
    padding: 0rem 10rem;
  }

  @media (max-width: 67.5em) {
    padding: 0rem 2rem;
    grid-template-columns: 1fr 1fr 20%;
  }

  @media (max-width: 48em) {
    padding: 0rem 2rem;
    grid-template-columns: 1fr 1fr;
  }

  &__image {
    cursor: pointer;
    margin-left: 10px;
    @media (max-width: 80em) {
      width: 20rem;
      height: auto;
    }

    @media (max-width: 67.5em) {
      width: 15rem;
      height: auto;
    }
  }

  &__text {
    cursor: pointer;
    font-size: 2rem;
    @media (max-width: 93.5em) {
      font-size: 2rem;
    }

    @media (max-width: 67.5em) {
      font-size: 1.5rem;
    }

    @media (max-width: 48em) {
      display: none;
    }
  }

  &__minimap {
    //display: flex;
    cursor: pointer;
    width: 100%;
    height: 100%;
    outline: none;
    //max-height: 15rem;

    &__alert {
      align-self: center;
      color: var(--primary-blue-dark);
    }
  }
}

.header-educ-space {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 48em) {
    display: flex;
    flex-direction: column;
  }

  & #educbanner {
    background-color: var(--primary-white);
    border: 1px solid var(--primary-blue);
    border-radius: 6px;
    margin: 6px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    height: 120px;
    width: 140px;
    box-shadow: 1px 1px 1px 1px var(--primary-blue),
      1px 1px 15px -5px var(--primary-blue);

    @media (max-width: 67.5em) {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 1rem;
      padding: 0.5rem;

      height: 48px;
      width: 140px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.error-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-error);
  position: sticky;
  top: 3.6rem;
  z-index: 2;
}
