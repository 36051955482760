$max-tab-count: 5;
$tab-wrap-border-radius: 6px;

.tab-container {
    margin: 0 auto;
    display: block;
    width: 100%;
    //background-color: dodgerblue;
    //max-width         : 800px;
}

.tab-wrap {
    transition: 0.2s box-shadow ease;
    border-radius: $tab-wrap-border-radius;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
    background-color: #fff;
    //margin          : 40px 0;
    box-shadow: var(--shadow-1);

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
}

.tab {
    display: none;
    font-size: 1.4rem;

    @for $i from 1 through $max-tab-count {
        &:checked:nth-of-type(#{$i}) ~ .tab__content:nth-of-type(#{$i}) {
            opacity: 1;
            transition: 0.5s opacity ease-in, 0.8s transform ease;
            position: relative;
            top: 0;
            z-index: 1;
            transform: translateY(0px);
            //text-shadow: 0 0 0;
        }
    }

    &:first-of-type:not(:last-of-type) + label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:not(:first-of-type):not(:last-of-type) + label {
        border-radius: 0;
    }

    &:last-of-type:not(:first-of-type) + label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:checked + label {
        background-color: #fff;
        box-shadow: 0 -1px 0 #fff inset;
        cursor: default;

        &:hover {
            box-shadow: 0 -1px 0 #fff inset;
            background-color: #fff;
        }
    }

    + label {
        box-shadow: 0 -1px 0 #eee inset;
        border-radius: $tab-wrap-border-radius $tab-wrap-border-radius 0 0;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: var(--primary-black);
        flex-grow: 3;
        text-align: center;
        background-color: var(--primary-blue-highlight-2);
        user-select: none;
        transition: 0.3s background-color ease, 0.3s box-shadow ease;
        height: 5rem;
        box-sizing: border-box;
        padding: 1.5rem;

        &:hover {
            background-color: var(--primary-blue-highlight);
            box-shadow: 0 1px 0 #f4f4f4 inset;
        }
    }

    &__content {
        padding: 1rem 2.5rem;
        background-color: transparent;
        position: absolute;
        width: 100%;
        z-index: -1;
        opacity: 0;
        left: 0;
        transform: translateY(-3px);
        border-radius: $tab-wrap-border-radius;
    }
}
