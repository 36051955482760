.custom-menu {
    max-width: 100% !important;

    .mat-menu-content {
        .mat-menu-item {
            line-height: 4rem;
            height: 4rem;
            font-size: 1.4rem;

            &:hover {
                background-color: var(--primary-blue-highlight-2);
            }
        }
    }
}
