.heading-1 {
    color: var(--color-primary);
    font-size: 2rem;
    font-weight: 500;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-sm {
    font-size: 1rem;
}

.text-sm-2 {
    font-size: 1.5rem;
}

.text-sm-3 {
    font-size: 1.75rem;
}

.text-md {
    font-size: 2rem;
    @media (max-width: 80em) {
        font-size: 1.5rem;
    }

    @media (max-width: 48em) {
        font-size: 1.2rem;
    }
}

.text-md-2 {
    font-size: 2.5rem;
    @media (max-width: 80em) {
        font-size: 2rem;
    }

    @media (max-width: 67.5em) {
        font-size: 1.5rem;
    }
}

.text-lg {
    font-size: 3rem;
}

.text-center {
    text-align: center;
}

.text-primary {
    color: var(--color-primary);
}

.text-primary-blue {
    color: var(--primary-blue);
}

.text-primary-dark {
    color: var(--primary-blue-dark);
}

.text-white-3 {
    color: var(--primary-white-3);
}

.text-dark {
    color: var(--primary-black);
}

.text-white {
    color: #fff;
}

.text-secondary {
    color: var(--color-primary-lighter);
}

.heading-2 {
    color: var(--color-primary);
    font-size: 1.5rem;
    font-weight: 500;
}

.header-primary {
    color: var(--primary-blue-dark);
    font-size: 2rem;
    font-weight: 500;
    height: 2rem;
}

.text-bold-500 {
    font-weight: 500;
}

.text-light-400 {
    font-weight: 400;
}

.text-green {
    color: var(--green-1);
}

.text-red {
    color: var(--red-1);
}

.text-purple {
    color: var(--color-purple);
}
