.card-prev-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;

    & > :nth-child(1) {
        height: 100%;
        grid-row: 1/4;
        display: grid;
        place-items: center;
    }

    /*@media (max-width: 80em) {
        padding: 0.3rem 5rem;
    }*/

    @media (max-width: 67.5em) {
        padding: 0.3rem 2.5rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }

    @media (max-width: 48em) {
        padding: 0.3rem 1rem;
    }
}

/*.card-prev {
    box-shadow: var(--shadow-1);
    border-radius: 10px;
    padding: 1rem;
    background-color: white;

    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        padding-bottom: 1rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;

        &__temperature {
            font-size: 3rem;
            font-weight: 500;
            color: var(--color-primary);
        }

        &__icon {
            font-size: 2rem;
            color: var(--color-primary-light);
        }
    }

    &__description {
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        padding: 0.5rem 0;
        font-weight: 400;
        padding-bottom: 1.5rem;
    }

    &__mesures {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        &__item {
            font-size: 1.5rem;

            &:nth-child(even) {
                justify-self: end;
            }
        }
    }
}*/
