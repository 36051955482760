.flash-info {
    position: sticky;
    bottom: 0;
    display: flex;
    border-top: 1px solid var(--primary-black-3);
    padding: 0rem 12rem;
    background-color: var(--primary-black);
    color: #fff;
    z-index: 2;

    @media (max-width: 48em) {
        //background-color: orange;
        padding: 0rem 0.25rem;
    }

    &__content {
        font-weight: 500;
        letter-spacing: 0.05rem;
    }

    //&__date {}
}
