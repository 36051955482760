.navbar {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  border-right: none;
  border-left: none;
  padding: 0 18rem;
  background-color: #fff;
  z-index: 2;

  position: sticky;
  top: -2px;
  box-shadow: var(--shadow-border);

  @media (max-width: 80em) {
    padding: 0 5rem;
  }

  @media (max-width: 67.5em) {
    padding: 0 2.5rem;
    //display: none;
  }

  @media (max-width: 48em) {
    padding: 0 1rem;
  }

  &__list {
    list-style: none;
    display: flex;
    @media (max-width: 56.25em) {
      display: none;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    letter-spacing: 0.15rem;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    //border-right: 1px solid var(--primary-blue-highlight);
    white-space: nowrap;

    color: var(--primary-blue-dark);
    font-size: 1.4rem;

    &:hover {
      color: var(--primary-blue);
      background-color: #fff;
    }

    fa-icon {
      outline: none;
    }
  }

  &__icon {
    font-size: 1.6rem;
  }

  &__menu {
    display: none;
    @media (max-width: 56.25em) {
      display: block;
      padding: 0rem 1.2rem;
      font-size: 2rem;
    }
  }

  &__search {
    display: flex;
    align-items: center;

    &__input {
      background-color: transparent;
      outline: none;
      border: none;
      transition: all 0.2s ease-in-out;

      @media (max-width: 67.5em) {
        display: none;
      }

      /*&:focus {
                padding-right: 4rem;
                //border-left  : transparent;
            }*/
    }

    &__icon {
      color: var(--primary-blue);
      padding: 0.5rem;

      @media (max-width: 67.5em) {
        display: none;
      }
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--primary-blue);
      font-weight: 600;
      letter-spacing: 0.05rem;
      opacity: 1;
      /* Firefox */
    }
  }
}

.active-link {
  background-color: #0098da;
  color: white;
  outline: none;
}

.navbar__link:hover {
  color: #0098da;
  background-color: #fff;
}
