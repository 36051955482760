.cs-paginator {
    background-color: #fff;
    box-shadow: var(--shadow-1);
    *{
        font-size: 1.5rem;
    }

    /*.p-link{
        font-size: 1.5rem;
    }*/
}
