.top-header {
  display: flex;
  justify-content: space-between;
  //background-color: var(--primary-blue);
  background-color: #28367f;
  color: var(--primary-white);
  padding: 0.3rem 15rem;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;

  @media (max-width: 80em) {
    padding: 0.3rem 5rem;
  }

  @media (max-width: 70.625em) {
    padding: 0.3rem 2.5rem;
    display: none;
  }

  @media (max-width: 48em) {
    padding: 0.3rem 1rem;
  }

  li {
    padding: 0.2rem 0.4rem;
    margin: 0.2rem;
    line-height: 1.5rem;
    border-radius: 100px;
    cursor: pointer;
    border: 1px solid #fff;

    &:hover {
      background-color: 0 2px 20px rgba(0, 0, 0, 0.2);
    }
  }

  &__left {
    display: flex;
    list-style: none;
  }

  &__right {
    display: flex;
    list-style: none;
  }
}

.top-link-active {
  background-color: #fff;
  color: var(--primary-blue);
}
